// store.js
import { configureStore } from "@reduxjs/toolkit";
import exampleReducer from "./slices/exampleSlice"; // Example slice

const store = configureStore({
    reducer: {
        example: exampleReducer // Add reducers as you create them
    }
});

export default store;