// exampleSlice.js
import { createSlice } from "@reduxjs/toolkit";

const exampleSlice = createSlice({
    name: "example",
    initialState: {
        count: 0
    },
    reducers: {
        increment: (state) => {
            state.count += 1;
        },
        decrement: (state) => {
            state.count -= 1;
        },
        setCount: (state, action) => {
            state.count = action.payload;
        }
    }
});

export const { increment, decrement, setCount } = exampleSlice.actions;
export default exampleSlice.reducer;