import { Link } from "react-router-dom";

function Navbar() {
    return (
        <nav className="navbar">
            <div className="container nav-container">
                <Link to="/" className="logo">
                    WispLink
                </Link>
                <ul className="nav-links">
                    <li>
                        <a href="#features">Features</a>
                    </li>
                    <li>
                        <a href="#how-it-works">How It Works</a>
                    </li>
                    <li>
                        <a href="#about-us">About</a>
                    </li>
                    <li>
                        <a href="#contact-us">Contact</a>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;