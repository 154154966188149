import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import createRoomImage from "../../assets/images/surface-coCGW4SsxX4-unsplash.jpg";
import startChattingImage from "../../assets/images/naipo-de-343EYF6fUEI-unsplash.jpg";
import heroImage from "../../assets/images/hero-image.png";

function Home({ serverUrl }) {
    const [socket, setSocket] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Create socket connection
        const newSocket = io(serverUrl);
        setSocket(newSocket);

        // Cleanup on unmount
        return () => {
            newSocket.disconnect();
        };
    }, []);

    const createRoom = () => {
        socket.emit('create-room', (newRoomId) => {
            console.log('Created room:', newRoomId);
            // Redirect to the new room
            navigate(`/room/${newRoomId}`);
        });
    };

    return (
        <div>
            <header className="hero">
                <div className="container hero-content">
                    <h1>Experience Seamless Video Calls</h1>
                    <p>
                        Connect with your friends and colleagues instantly with our easy-to-use video chat platform.
                    </p>
                    <button className="btn btn-primary" onClick={createRoom}>
                        Get Started
                    </button>
                </div>
            </header>

            <section className="features" id="features">
                <div className="container">
                    <h2>Features</h2>
                    <div className="features-grid">
                        <div className="feature-item">
                            <i className="fas fa-video fa-3x"></i>
                            <h3>High-Quality Video</h3>
                            <p>Enjoy crystal-clear video and audio during your calls.</p>
                        </div>
                        <div className="feature-item">
                            <i className="fas fa-lock fa-3x"></i>
                            <h3>Secure Connections</h3>
                            <p>Your conversations are private and encrypted.</p>
                        </div>
                        <div className="feature-item">
                            <i className="fas fa-users fa-3x"></i>
                            <h3>Easy Collaboration</h3>
                            <p>Connect with multiple participants effortlessly.</p>
                        </div>
                        <div className="feature-item">
                            <i className="fas fa-globe fa-3x"></i>
                            <h3>Browser-Based</h3>
                            <p>No downloads required. Works directly in your browser.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="how-it-works" id="how-it-works">
                <div className="container">
                    <h2>How It Works</h2>
                    <div className="steps">
                        <div className="step">
                            <span className="step-number">1</span>
                            <h3>Create a Room</h3>
                            <p>Click "Get Started" to generate a unique room link.</p>
                            {/* <img src={createRoomImage} alt="Create a Room" style={{ width: '100%', height: 'auto', display: 'block', marginTop: '20px' }} /> */}
                        </div>
                        <div className="step">
                            <span className="step-number">2</span>
                            <h3>Share the Link</h3>
                            <p>Send the link to friends or colleagues you want to chat with.</p>
                        </div>
                        <div className="step">
                            <span className="step-number">3</span>
                            <h3>Start Chatting</h3>
                            <p>Once they join, begin your seamless video conversation.</p>
                            {/* <img src={startChattingImage} alt="Start Chatting" style={{ width: '100%', height: 'auto', display: 'block', marginTop: '20px' }} /> */}
                        </div>
                    </div>
                </div>
            </section>


            <section className="about-us" id="about-us">
                <div className="container">
                    <h2>About Us</h2>
                    <p>
                        We are dedicated to making video communication simple and accessible for everyone. Our platform leverages cutting-edge WebRTC technology to provide high-quality video calls directly in your browser.
                    </p>
                </div>
            </section>

            <section className="contact-us" id="contact-us">
                <div className="container">
                    <h2>Contact Us</h2>
                    <p>
                        Have questions or feedback? Reach out to us at{' '}
                        <a href="mailto:support@wisplink.app">support@wisplink.app</a>.
                    </p>
                </div>
            </section>
        </div>
    );
}

export default Home;