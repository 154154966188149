import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios'; // Import axios
import './CopyLink.css';

const CopyLink = ({ serverUrl }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const copyToClipboard = () => {
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(window.location.href)
                .then(() => {
                    setModalMessage('Link copied to clipboard!');
                    setShowModal(true);
                })
                .catch((err) => {
                    console.error('Failed to copy: ', err);
                    setModalMessage('Failed to copy link');
                    setShowModal(true);
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = window.location.href;
            textArea.style.position = 'fixed';
            textArea.style.top = 0;
            textArea.style.left = 0;
            textArea.style.width = '2em';
            textArea.style.height = '2em';
            textArea.style.padding = 0;
            textArea.style.border = 'none';
            textArea.style.outline = 'none';
            textArea.style.boxShadow = 'none';
            textArea.style.background = 'transparent';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    setModalMessage('Link copied to clipboard!');
                } else {
                    setModalMessage('Failed to copy link');
                }
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
                setModalMessage('Failed to copy link');
            }
            document.body.removeChild(textArea);
            setShowModal(true);
        }
    };

    const sendEmail = async () => {
        if (!email) {
            setModalMessage('Please enter a valid email address.');
            setShowModal(true);
            return;
        }

        try {
            const response = await axios.post(`${serverUrl}/send-email`, {
                to: email,
                subject: 'Someone Is Trying To Call You On WispLink!',
                text: `${window.location.href}`,
            });

            if (response.status === 200) {
                setModalMessage(`Link sent to ${email}!`);
            } else {
                setModalMessage('Failed to send email.');
            }
        } catch (error) {
            console.error('Error:', error);
            setModalMessage('An error occurred while sending the email.');
        }

        setShowModal(true);
        setEmail('');
    };

    const sendTextMessage = () => {
        if (!phoneNumber) {
            setModalMessage('Please enter a valid phone number.');
            setShowModal(true);
            return;
        }
        
        setModalMessage(`Link sent to ${phoneNumber}!`);
        setShowModal(true);
        setPhoneNumber('');
    };

    return (
        <div className="container-style">
            <h5 className="text-center mb-4">Share Link</h5>
            <p className="text-center">Share this link with others to join the room:</p>
            
            <div className="input-group-style">
                <input
                    type="text"
                    className="form-control input-style"
                    value={window.location.href}
                    readOnly
                />
                <Button variant="outline-secondary" onClick={copyToClipboard} className="copy-button-style">
                    Copy Link
                </Button>
            </div>

            <Form.Group controlId="email" className="mb-3">
                <Form.Label className="label-style">Send Link via Email</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Enter email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={phoneNumber !== ''}
                    className="input-style"
                />
                <Button
                    variant="primary"
                    className="button-style"
                    onClick={sendEmail}
                    disabled={phoneNumber !== ''}
                >
                    Send Email
                </Button>
            </Form.Group>

            <Form.Group controlId="phone" className="mb-3">
                <Form.Label className="label-style">Send Link via Text Message</Form.Label>
                <Form.Control
                    type="tel"
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    disabled={email !== ''}
                    className="input-style"
                />
                <Button
                    variant="primary"
                    className="button-style"
                    onClick={sendTextMessage}
                    disabled={email !== ''}
                >
                    Send Text Message
                </Button>
            </Form.Group>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Notification</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CopyLink;
