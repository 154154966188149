
function Footer() {
    return (
        <footer>
            <div className="container footer-container">
                <p>&copy; {new Date().getFullYear()} WispLink. All rights reserved.</p>
                <div className="social-icons">
                    <a href="#" aria-label="Twitter">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a href="#" aria-label="Facebook">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#" aria-label="LinkedIn">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;