import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import {
  useParams,
  useNavigate
} from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button

// Components
import CopyLink from './CopyLink';

function Room({ serverUrl }) {
    const { roomId } = useParams();
    const navigate = useNavigate();
    const [socket, setSocket] = useState(null);
    const [joinedRoom, setJoinedRoom] = useState(false);
    const [messages, setMessages] = useState([]);
    const [isReady, setIsReady] = useState(false);
    const [peerReady, setPeerReady] = useState(false);
    const [showModal, setShowModal] = useState(false); // State for modal visibility
    const localStreamRef = useRef(null);
    const remoteStreamRef = useRef(null);
    const peerConnectionRef = useRef(null);
    const isInitiatorRef = useRef(false);

    useEffect(() => {
      // Create socket connection
      const newSocket = io(serverUrl);
      setSocket(newSocket);
  
      // Cleanup on unmount
      return () => {
        newSocket.disconnect();
      };
    }, []);
  
    useEffect(() => {
      if (!socket) return;
  
      // Set up event handlers before emitting 'join-room'
  
      // Determine if the user is the initiator
      socket.on('room-created', () => {
        isInitiatorRef.current = true;
        console.log('You are the room initiator');
      });
  
      // Handle 'user-joined' event
      socket.on('user-joined', () => {
        console.log('A new user has joined the room.');
        setMessages((prevMessages) => [...prevMessages, 'A new user has joined the room.']);
      });
  
      // Handle 'peer-ready' event
      socket.on('peer-ready', () => {
        setPeerReady(true);
        console.log('Peer is ready');
        // If initiator and both are ready, create offer
        if (isInitiatorRef.current && isReady) {
          createOffer();
        }
      });
  
      // Handle 'offer' event
      socket.on('offer', async (offer) => {
        console.log('Received offer:', offer);
        if (peerConnectionRef.current) {
          await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(offer));
          const answer = await peerConnectionRef.current.createAnswer();
          await peerConnectionRef.current.setLocalDescription(answer);
          socket.emit('answer', peerConnectionRef.current.localDescription, roomId);
        }
      });
  
      // Handle 'answer' event
      socket.on('answer', async (answer) => {
        console.log('Received answer:', answer);
        if (peerConnectionRef.current) {
          await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(answer));
        }
      });
  
      // Handle 'candidate' event
      socket.on('candidate', async (candidate) => {
        console.log('Received candidate:', candidate);
        if (peerConnectionRef.current) {
          try {
            await peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(candidate));
          } catch (e) {
            console.error('Error adding received ice candidate', e);
          }
        }
      });
  
      // Handle disconnect
      socket.on('disconnect', () => {
        console.log('Disconnected from server');
        setMessages((prevMessages) => [...prevMessages, 'Disconnected from server']);
      });
  
      // Emit 'join-room' after event handlers are set up
      socket.emit('join-room', roomId);
      setJoinedRoom(true);
      console.log('Joined room:', roomId);
      setMessages((prevMessages) => [...prevMessages, `Joined room: ${roomId}`]);
  
      // Cleanup on unmount
      return () => {
        socket.off('room-created');
        socket.off('user-joined');
        socket.off('peer-ready');
        socket.off('offer');
        socket.off('answer');
        socket.off('candidate');
        socket.off('disconnect');
      };
    }, [socket, roomId, isReady]);
  
    const handleStartConnection = async () => {
      await initializeMediaAndConnection();
      setIsReady(true);
      socket.emit('ready', roomId);
      console.log('You are ready');
  
      // If both peers are ready and not the initiator, send a message to the initiator
      if (!isInitiatorRef.current && peerReady) {
        // The initiator will handle creating the offer
      }
    };
  
    const initializeMediaAndConnection = async () => {
      try {
        // Get local media stream
        const localStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        localStreamRef.current.srcObject = localStream;
  
        // Create RTCPeerConnection
        const configuration = {
          iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
        };
        const peerConnection = new RTCPeerConnection(configuration);
        peerConnectionRef.current = peerConnection;
  
        // Add local stream tracks to peer connection
        localStream.getTracks().forEach((track) => {
          peerConnection.addTrack(track, localStream);
        });
  
        // Handle ICE candidates
        peerConnection.onicecandidate = (event) => {
          if (event.candidate) {
            socket.emit('candidate', event.candidate, roomId);
          }
        };
  
        // Handle remote stream
        peerConnection.ontrack = (event) => {
          const [remoteStream] = event.streams;
          remoteStreamRef.current.srcObject = remoteStream;
        };
      } catch (error) {
        console.error('Error accessing media devices.', error);
        alert('Error accessing media devices: ' + error.message);
        navigate('/');
      }
    };
  
    const createOffer = async () => {
      if (peerConnectionRef.current) {
        const offer = await peerConnectionRef.current.createOffer();
        await peerConnectionRef.current.setLocalDescription(offer);
        socket.emit('offer', peerConnectionRef.current.localDescription, roomId);
      }
    };
  
     // Function to handle ending the call
     const handleEndCall = () => {
      // Stop local media tracks
      if (localStreamRef.current && localStreamRef.current.srcObject) {
        localStreamRef.current.srcObject.getTracks().forEach((track) => {
          track.stop();
        });
        localStreamRef.current.srcObject = null;
      }
  
      // Close peer connection
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
        peerConnectionRef.current = null;
      }
  
      // Stop remote video
      if (remoteStreamRef.current) {
        remoteStreamRef.current.srcObject = null;
      }
  
      // Show the tip modal
      setShowModal(true);
    };
  
    return (
      <div className="container">
        <h1 className="text-center mt-4">Room ID: {roomId}</h1>
        {joinedRoom && <p className="text-center">You have joined the room: {roomId}</p>}
        
        <CopyLink serverUrl={serverUrl} />
        {!isReady && (
          <div className="text-center">
            <button className="btn btn-success" onClick={handleStartConnection} disabled={!isInitiatorRef.current && !peerReady}>
              Start Connection
            </button>
          </div>
        )}
        {isReady && (
          <div className="text-center mt-3">
            <button className="btn btn-danger" onClick={handleEndCall}>
              End Call
            </button>
          </div>
        )}
        <div className="text-center mt-3">
          <button className="btn btn-secondary" onClick={() => navigate('/')}>
            Back to Home
          </button>
        </div>
        <div className="row mt-5">
          <div className="col-md-6 text-center">
            <h2>Local Video</h2>
            <video ref={localStreamRef} autoPlay playsInline muted className="video" />
          </div>
          <div className="col-md-6 text-center">
            <h2>Remote Video</h2>
            <video ref={remoteStreamRef} autoPlay playsInline className="video" />
          </div>
        </div>
        <div className="mt-5">
          <h2>Messages:</h2>
          <ul className="list-group">
            {messages.map((msg, idx) => (
              <li key={idx} className="list-group-item">
                {msg}
              </li>
            ))}
          </ul>
        </div>
  
        {/* Tip Modal */}
        <Modal show={showModal} onHide={() => navigate('/')}>
          <Modal.Header closeButton>
            <Modal.Title>Leave a Tip</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Thank you for using WispLink! Would you like to leave a tip?</p>
            <div className="d-flex flex-column align-items-center">
              <Button
                variant="primary"
                className="mb-2"
                href="https://venmo.com/Garrett-Kegel"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tip via Venmo
              </Button>
              {/* <Button
                variant="success"
                className="mb-2"
                href="https://cash.app/$your-cashapp-cashtag"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tip via Cash App
              </Button> */}
              {/* Add other services here */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => navigate('/')}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  export default Room;