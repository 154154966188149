// App.js
import React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";
import { Provider as ReduxProvider } from "react-redux";
import store from "./store";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import './App.css'; // Import the CSS file
import 'bootstrap/dist/css/bootstrap.min.css';

// Pages
import Home from "./pages/Home";
import Room from "./pages/Room";

// Components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

// Replace with your server URL
const SERVER_URL =
  process.env.NODE_ENV === 'production'
    ? `https://www.wisplink.app` // Use your actual Heroku app domain
    : `http://localhost:5000`; // Localhost during development

const client = new ApolloClient({
  uri: `${SERVER_URL}/graphql`,
  cache: new InMemoryCache()
});

function App() {
  return (
    <ApolloProvider client={client}>
      <ReduxProvider store={store}>
        <Router>
          <div>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home serverUrl={SERVER_URL} />} />
              <Route path="/room/:roomId" element={<Room serverUrl={SERVER_URL} />} />
            </Routes>
            <Footer />
          </div>
        </Router>
      </ReduxProvider>
    </ApolloProvider>
  );
}

export default App;